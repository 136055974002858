class CONFIG {
  NAP_ADDR: string = process.env.REACT_APP_NAP_ADDR || '/'
  LANGUAGES: string[] = process.env.REACT_APP_LANGUAGES?.split(',') || [
    'en',
    'es',
    'ru',
  ]
}

const conf = new CONFIG()
export default conf
