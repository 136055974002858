import i18next from 'i18next'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { AiFillCamera } from 'react-icons/ai'
import { RiArrowLeftLine } from 'react-icons/ri'
import ReactResizeDetector from 'react-resize-detector'
import { Redirect } from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Content,
  Form,
  IconButton,
  Loader,
  Panel,
} from 'rsuite'

import { CameraCanvas } from 'components/cameras'
import { WEBCAM } from 'components/players'
import CONFIG from 'config'
import { Spinner, alert } from 'content'
import NAP, { AuthSettings, Detected, Model, User } from 'nap'
import './auth.less'

interface Props extends WithTranslation {
  setUser(user: User): void
}

interface State {
  formdata: {
    username: string
    password: string
  }

  lang: string
  redirect: boolean
  error?: string

  settings: AuthSettings
  cameraon: boolean
  step: 1 | 2

  detected: Detected
  canvas: {
    w: number
    h: number
  }
}

export class Auth extends React.Component<Props, State> {
  state = {
    formdata: {
      username: '',
      password: '',
    },
    step: 1,
    lang: i18next.language,
    canvas: { w: 0, h: 0 },
  } as State

  refWebcam = React.createRef<WEBCAM>()

  componentDidMount() {
    NAP.authSettings()
      .then((s) => this.setState({ settings: s }))
      .catch(console.error)
  }

  success = () => {
    window.clearTimeout(this.timeout)

    NAP.user().then(this.props.setUser).catch(this.error)
    if (window.location.pathname.startsWith('/auth')) {
      this.setState({ redirect: true })
    }
  }

  error = (err: Error) => {
    const { t } = this.props

    console.error(err.message)
    alert(t('authorization failed'))
    if (err.message.includes('expired')) {
      NAP.logout()
    }
  }

  //
  // handlers
  //

  handleForm = (formdata: any) => {
    this.setState({ formdata })
  }

  handleSubmit = () => {
    const { username, password } = this.state.formdata
    NAP.auth(username, password).then(this.success).catch(this.error)
  }

  handleToggleCamera = () => {
    const { cameraon } = this.state
    this.setState({ cameraon: !cameraon })
  }

  timeout = 0
  handleFaceAuth = () => {
    if (this.timeout) window.clearTimeout(this.timeout)
    this.timeout = window.setTimeout(this.handleFaceAuth, 2000)

    const cam = this.refWebcam.current
    if (!cam) return

    const { settings, cameraon, step } = this.state

    // auth 2-factor: username, password, face
    if (settings.faceauth_withcreds && step === 2) {
      cam.getFrame((frame: any) => {
        if (!frame) return

        const { username, password } = this.state.formdata
        NAP.auth(username, password, frame)
          .then(this.setDetected)
          .catch(this.error)
      })
      return
    }

    // auth only by face
    if (settings.faceauth && cameraon) {
      cam.getBlob((frame: any) => {
        if (!frame) return

        NAP.faceAuth(frame).then(this.setDetected).catch(this.error)
      })
    }
  }

  setDetected = (d: Detected | any) => {
    window.clearTimeout(this.timeout)

    if (!d.camera) d.camera = {} as Model
    if (!d.analytics) d.analytics = {} as Model

    this.setState({ detected: d })
    if (d.token) this.success()
    else window.setTimeout(this.handleFaceAuth, 200)
  }

  handleWebcamSize = (w?: number, h?: number) => {
    this.setState({ canvas: { w: w || 0, h: h || 0 } })
  }

  handleStep = (step: 1 | 2) => {
    this.setState({ step, cameraon: step === 2 })
  }

  changeLanguage = (lang: string) => {
    i18next.changeLanguage(lang)
    this.setState({ lang: lang })
  }

  //
  // render
  //

  render() {
    const { t } = this.props
    const { redirect, formdata, settings, cameraon, detected, canvas, step } =
      this.state
    if (redirect) return <Redirect to='/' />

    return (
      <Content className='auth'>
        <Panel shaded>
          <Form
            formValue={formdata}
            onChange={this.handleForm}
            autoComplete='off'
            fluid>
            <Spinner className='spinner-center' />

            {cameraon ? (
              <div className='auth-camera_container'>
                <ReactResizeDetector
                  handleWidth
                  handleHeight
                  onResize={this.handleWebcamSize}>
                  <WEBCAM
                    ref={this.refWebcam}
                    className='auth-camera'
                    onUserMedia={this.handleFaceAuth}
                  />
                </ReactResizeDetector>
                {detected && (
                  <CameraCanvas
                    // eslint-disable-next-line
                    analytics={{ [0]: detected }}
                    width={canvas.w}
                    height={canvas.h}
                  />
                )}
              </div>
            ) : (
              <div>
                <Form.Group>
                  <Form.ControlLabel>{t('auth.username')}</Form.ControlLabel>
                  <Form.Control name='username' />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>{t('auth.password')}</Form.ControlLabel>
                  <Form.Control name='password' type='password' />
                </Form.Group>
              </div>
            )}

            <ButtonToolbar
              justifyContent='center'
              style={{ marginTop: 40, marginBottom: 20 }}>
              {!settings && (
                <Button
                  appearance='primary'
                  className='auth-btn_submit'
                  disabled>
                  <Loader />
                </Button>
              )}

              {settings && !settings.faceauth_withcreds && (
                <Button
                  onClick={this.handleSubmit}
                  type='submit'
                  disabled={cameraon}
                  appearance='primary'
                  className='auth-btn_submit'>
                  {t('auth.submit')}
                </Button>
              )}

              {settings && settings.faceauth_withcreds && step === 1 && (
                <Button
                  onClick={() => this.handleStep(2)}
                  type='submit'
                  appearance='primary'
                  disabled={cameraon}
                  className='auth-btn_submit'>
                  {t('auth.next')} <span style={{ opacity: 0.5 }}>1/2</span>
                </Button>
              )}

              {settings && settings.faceauth_withcreds && step === 2 && (
                <IconButton
                  icon={<RiArrowLeftLine />}
                  appearance='default'
                  onClick={() => this.handleStep(1)}
                />
              )}

              {settings && settings.faceauth && step === 1 && (
                <IconButton
                  icon={cameraon ? <RiArrowLeftLine /> : <AiFillCamera />}
                  appearance={cameraon ? 'default' : 'primary'}
                  onClick={this.handleToggleCamera}
                />
              )}
            </ButtonToolbar>
          </Form>
        </Panel>

        <Panel className='subpanel'>
          <ButtonGroup>
            {CONFIG.LANGUAGES.map((lang: string) => {
              return (
                <Button key={lang} onClick={() => this.changeLanguage(lang)}>
                  {lang.toUpperCase()}
                </Button>
              )
            })}
          </ButtonGroup>
        </Panel>

        <div className='subtitle'>
          supported by{' '}
          <a
            href='https://neuroview.tech'
            target='_blank'
            rel='noopener noreferrer'>
            NeuroView
          </a>
        </div>
      </Content>
    )
  }
}

export default withTranslation()(Auth)
